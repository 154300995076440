import { Button, Stack, Typography } from '@mui/material';
import { Colors, Emails } from '../../enums/enums';

const HomeInactive = () => {
  return (
    <>
      <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
        <Typography variant="h4" sx={{ textAlign: 'center', color: Colors.TextElevated, my: 6 }}>
          Your Account Is Inactive
        </Typography>
        <Typography style={{ textAlign: 'center', fontSize: '1.2rem', color: Colors.Text }}>
          Please reach out to <b>{Emails.MainEmail}</b> to enroll in one of our programs.
        </Typography>
        <Button
          variant="contained"
          color="success"
          sx={{ backgroundColor: Colors.TextElevatedLighter, width: '230px' }}
          href="https://www.besthuman.io/leadership-programs"
          target="_blank"
        >
          Explore Programs
        </Button>
      </Stack>
    </>
  );
};

export default HomeInactive;