import api from '../../services/api';
import axios from 'axios';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { Colors } from '../../enums/enums';
import { useEffect, useState } from 'react';
import QuoteUp from '../../assets/quote_up.png';
import QuoteDown from '../../assets/quote_down.png';

const LeadershipQuotes = () => {
  const [quote, setQuote] = useState('loading quote...');
  const [author, setAuthor] = useState('');

  useEffect(() => {
    const source = axios.CancelToken.source();

    api
      .get('/quotes/quote-of-the-day', { cancelToken: source.token })
      .then(({ data: { quote, author } }) => {
        setQuote(quote);
        setAuthor(author);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Failed to fetch quote:', error);
        }
      });

    return () => {
      source.cancel('Component unmounted, canceling request');
    };
  }, []);

  return (
    <>
      <Card
        elevation={0}
        sx={{
          minWidth: 'auto',
          maxWidth: `${Math.max(50, Math.min(quote.length * 0.7, 85))}%`, // Dynamically adjust width for shorter and longer quotes
          height: 'auto',
          backgroundColor: Colors.BackgroundMainLighter,
          boxShadow: 'rgba(0, 0, 0, 0.1) -4px 9px 25px -6px',
          borderRadius: 4,
          position: 'relative',
          transition: 'max-width 0.3s ease-in-out', // Smooth width adjustment
          '&:hover': {
            boxShadow: '0 0 10px 5px rgba(177, 224, 180, 0.19)'
          }
        }}
      >
        <CardContent sx={{ position: 'relative', padding: 2 }}>
          <img style={{ position: 'absolute', top: 10, left: 15, width: '35px' }} src={QuoteDown} />
          <Typography
            component="div"
            sx={{
              color: Colors.TextElevated,
              fontFamily: 'Arial, sans-serif',
              fontSize: '1.4rem',
              ml: 7,
              mt: 1,
              mr: 4,
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap'
            }}
          >
            {quote}
          </Typography>
          <Typography sx={{ color: 'grey', textAlign: 'end', mb: 2, mt: 1, mr: 7 }}>― {author}</Typography>
          <img style={{ position: 'absolute', bottom: 10, right: 15, width: '35px' }} src={QuoteUp} />
        </CardContent>
      </Card>
    </>
  );
};

export default LeadershipQuotes;