import { Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { createStyles, Link, makeStyles, Theme } from '@material-ui/core';
import {Colors} from "../../enums/enums";

interface Props {
  
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divQuestion: {
      marginTop: '50px',
      backgroundColor: Colors.BackgroundDrawer,
      padding: '20px 20px 20px 20px',
      // height: '200px'
    },
    question: {
      fontFamily: 'Roboto',
      fontSize: '24px',
      fontWeight: 'normal'
    },
    answer: {
      marginTop: '10px'
    },
    button: {
      backgroundColor: '#db0054',
      color: '#ffffff',
      padding: '10px 20px',
      "&:hover": {
        backgroundColor: '#C5004B'
      }
    }
  }),
);

function QuestionsBodyMentoring(props: Props): ReactElement {
  const classes = useStyles();

  return (
    <Stack direction='column' spacing={2}>
      <>
      <div id="q1" style={{height: '20px'}} />
      <div id="q1" className={classes.divQuestion} >
        <span className={classes.question} >How many sessions are there?</span>
        <Typography className={classes.answer}>Up to 2 mentoring sessions per month, 30 minutes each  OR up to 1 hour session per month (if mentor and mentee agree on this).</Typography>
      </div>
      </>
      <>
      <div id="q2" style={{height: '20px'}} />
      <div id="q2_" className={classes.divQuestion} >
        <span className={classes.question} >What do you look at when making a match?</span>
        <Typography className={classes.answer}>
          Our mentors have 10+ years of experience behind them and our mentees are ambitious women looking to get the guidance they need to grow in their careers or sometimes, simply, just have a personal board of advisors. They range from senior managers to executive leaders, VP’s, serial entrepreneurs and more. We base our matches on areas mentees want to grow in such as goals and skills. Not always will they be in the same role or industry as you, it’s relevance and experience in the topic of guidance we match you on.
          
          Rest assured, if the match isn’t a good fit, let us know and we can find another match.
          </Typography>
      </div>
      </>
      <>
      <div id="q3" style={{height: '20px'}} />
      <div id="q3_" className={classes.divQuestion} >
        <span className={classes.question} >How can I prepare for my sessions?</span>
        <Typography>
        Check out our resource guides here: 
        <br/>
         <Link href="https://locelle.com/wp-content/uploads/2021/05/Meet-and-Greet-Locelle-Mentorship-Session-Guide.pdf">Meet and Greet Guide</Link>
        <br/>
        <Link href="https://locelle.com/wp-content/uploads/2021/06/Locelle-Mentorship-Sessions-Value-Guide-Mentee.pdf">Regular Sessions Guide</Link>
        <br/>
        <Link href="https://locelle.com/wp-content/uploads/2021/06/Locelle-Meeting-Agenda.pdf">Meeting Agenda Template</Link>
        <br/>
        <Link href="https://locelle.com/wp-content/uploads/2021/06/Locelle-Session-Prep-Form.pdf">Session Prep Form</Link>
        <br/>
        <Link href="https://locelle.com/wp-content/uploads/2021/08/Our-Process-Mentees.pdf">Our Process Timeline</Link>
        
 
        </Typography>
      </div>
      </>
      <>
      <div id="q4" style={{height: '20px'}} />
      <div id="q4_" className={classes.divQuestion} >
        <span className={classes.question} >What if I'm not clear about what my goals are?</span>
        <Typography>
        That's okay! Your goals can range from big to small. We recommend taking 5 minutes before your meet & greet to reflect on where you are in your career and some possible goals. Chat about these ideas with your mentor and take 5 minutes post meet & greet to narrow in on which goals resonated with you and which you will work on for the remainder of your mentorship. 

        Remember that these sessions are for both helping you learn from leaders who have been there, done that but also, to help navigate workplace topics that may occur (such as a new conflict or new position).
        </Typography>
      </div>
      </>
      <div id="q5" style={{height: '20px'}} />
      <div id="q5_"  className={classes.divQuestion} >
        <span className={classes.question} >What happens if session(s) are missed or canceled?</span>
        <Typography>
        If a mentee cancels or doesn’t show up, that session is missed and may not be rescheduled. However, if a mentor cancels, our team can help reschedule with the same or a different mentor depending on desire and availability. 

        You may be able to reschedule a missed session if you canceled 7 days before the session and your mentor has availability.
        </Typography>
      </div>
      <div id="q6" style={{height: '20px'}} />
      <div id="q6_" className={classes.divQuestion}>
        <span className={classes.question} >How are sessions scheduled?</span>
        <Typography>
        Sessions are scheduled via the BestHuman Portal by Client Success at this time based on mutual availability. All updates can be viewed by the mentee, mentor and the appointed Locelle Client Success Manager, including any rescheduling.
        </Typography>
      </div>
      <div id="q7" style={{height: '20px'}} />
      <div id="q7_" className={classes.divQuestion}>
        <span className={classes.question} >What if my mentor or mentee is late more than once?</span>
        <Typography>
        Email your appointed Client Success Manager and we will resolve as required. Time is precious for all parties and we want to ensure a good experience.
        </Typography>
      </div>
      <div id="q8" style={{height: '20px'}} />
      <div id="q8_" className={classes.divQuestion}>
      <span className={classes.question} >If my mentor or mentee doesn’t seem engaged, how should I manage this?</span>
        <Typography>
        We provide our mentor and mentee resources and guides for the mentorship sessions. We strongly urge you to read these first, apply the steps and come to sessions as prepared as possible. If this doesn’t help, contact your Client Success Manager.
        </Typography>
      </div>
      <div id="q9" style={{height: '20px'}} />
      <div id="q9_" className={classes.divQuestion} >
        {/* <div style={{height: '100px', backgroundColor: '#f1eeee', marginTop: '70px', padding: '10px'}}> */}
        <span className={classes.question} >What if I come across technical difficulties?</span>
        <Typography>Email your appointed Locelle Client Success Manager and we will resolve as required.</Typography>
        {/* </div> */}
        
      </div>
      
    </Stack>
  )
}

export default QuestionsBodyMentoring