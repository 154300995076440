import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Box, Fab, Tooltip } from '@mui/material';
import { Colors, SessionStageType } from '../../enums/enums';
import { ConfirmationDialog } from '../../components/dialogs/ConfirmationDialog';
import CoachingJitsiMeetingRoom from './CoachingJitsiMeetingRoom';
import NotesEditor from './NotesEditor';
import { User } from './CoachingSessionFullDTO';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      width: '70px',
      marginRight: '0px',
      position: 'absolute',
      backgroundColor: '#040404'
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1
    }
  })
);

interface Props {
  roomOrganizerName: string;
  sessionId: string;
  cohortId: string;
  stage: SessionStageType;
  onConfirmedClose: () => void;
  sessionTopic?: string;
  participants: User[];
}

export default function CoachingMeetingRoomDialog({
  roomOrganizerName,
  sessionId,
  cohortId,
  stage,
  onConfirmedClose,
  sessionTopic,
  participants
}: Props) {
  const classes = useStyles();

  const [preMeeting, setPreMeeting] = useState(true);
  const [notesOpened, setNotesOpened] = useState(false);
  const [closeMeetingDialogOpen, setCloseMeetingDialogOpen] = useState(false);

  const userId = localStorage.getItem('LOCELLE:USER');

  const handleClose = () => {
    setNotesOpened(false);
    onConfirmedClose();
  };

  const handleClosePreMeeting = () => {
    setPreMeeting(false);
  };

  return (
    <>
      <Tooltip title="Leave the meeting" arrow>
        <Fab
          variant="extended"
          size="small"
          sx={{
            backgroundColor: '#B7412F',
            color: 'white',
            position: 'absolute',
            top: '1%',
            left: '1%'
          }}
          onClick={() => setCloseMeetingDialogOpen(true)}
        >
          <CloseIcon />
        </Fab>
      </Tooltip>
      {!notesOpened && (
        <Tooltip title="Open notes" arrow>
          <Fab
            variant="extended"
            color="success"
            sx={{
              position: 'absolute',
              top: '90px',
              right: '11px'
            }}
            onClick={() => setNotesOpened(true)}
          >
            <FormatListBulletedIcon />
          </Fab>
        </Tooltip>
      )}
      <Box sx={{ p: 0, height: '100%' }}>
        <Box
          sx={{
            width: notesOpened ? '60%' : '100%',
            height: '100%',
            display: 'inline-block',
            backgroundColor: Colors.BackgroundDrawer
          }}
        >
          {preMeeting && (
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          )}
          <CoachingJitsiMeetingRoom
            sessionId={sessionId}
            sessionTopic={sessionTopic}
            roomOrganizerName={roomOrganizerName}
            handleClose={handleClose}
            hidePreMeetingOptions={handleClosePreMeeting}
          />
        </Box>
        {notesOpened && (
          <Box
            sx={{
              width: '40%',
              height: '100%',
              display: 'inline-block',
              verticalAlign: 'top'
            }}
          >
            <NotesEditor
              participants={participants}
              cohortId={cohortId}
              closeBtnOnLeft={true}
              onClose={() => setNotesOpened(false)}
              userId={userId}
            />
          </Box>
        )}
      </Box>
      {closeMeetingDialogOpen && (
        <ConfirmationDialog
          isOpen={closeMeetingDialogOpen}
          title="Leave the meeting?"
          message="Are you sure you want to leave the meeting?"
          onConfirmBtn={() => {
            setCloseMeetingDialogOpen(false);
            handleClose();
          }}
          onCancelBtn={() => setCloseMeetingDialogOpen(false)}
        />
      )}
    </>
  );
}