import { Stack, Typography } from '@mui/material';
import { Colors, Emails } from '../../enums/enums';

const HomeNew = () => {
  return (
    <>
      <Stack direction="column" spacing={2} justifyContent="center">
        <Typography variant="h4" sx={{ textAlign: 'center', color: Colors.TextElevated, my: 6 }}>
          Account Created Successfully
        </Typography>
        <Typography style={{ textAlign: 'center', fontSize: '1.3rem', color: Colors.Text }}>
          Your account has been successfully created and is now pending approval. <br />
          Once it is reviewed and approved, you will receive a confirmation email with further instructions. <br />
          If you have any questions in the meantime, feel free to contact our support team at <b>{Emails.MainEmail}</b>.
        </Typography>
      </Stack>
    </>
  );
};

export default HomeNew;