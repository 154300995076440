export default {
  all: {
    clientId: '',
    clientName: '',
    email: '',
    alternateEmail: '',
    firstName: '',
    lastName: '',
    city: '',
    country: '',
    timezone: '',
    age: '',
    title: '',
    experience: '',
    leadershipExperience: '',
    company: '',
    linkedin: '',
    learningStyle: '',
    careerGoals: '',
    accessibility: '',
    confidentiality: ''
  }
};