import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Colors } from '../../enums/enums';

interface Props {
  isOpen: boolean;
  title: string | any;
  message: string | any;
  onConfirmBtn: () => void;
  onCancelBtn: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
}

export const ConfirmationDialog = (props: Props) => {
  return (
    <Dialog maxWidth="md" open={props.isOpen}>
      <DialogTitle sx={{ backgroundColor: Colors.BackgroundDrawer }} id="confirm-dialog-title">
        {props.title}
      </DialogTitle>
      <DialogContent sx={{ height: '150px', width: '450px' }}>
        <DialogContentText sx={{ mt: 5 }} id="confirm-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button variant="outlined" color="inherit" sx={{ mr: 2, color: 'grey' }} onClick={props.onCancelBtn}>
          {props.cancelBtnText?.toUpperCase() || 'CANCEL'}
        </Button>
        <Button variant="contained" color="success" onClick={props.onConfirmBtn} autoFocus>
          {props.confirmBtnText?.toUpperCase() || 'CONFIRM'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};