import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  Grow,
  LinearProgress,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { Colors, GroupStatus, SessionStageType } from '../../enums/enums';
import { useDispatch, useSelector } from 'react-redux';
import {
  findCoachingSessionsByUserId,
  getCoachingSessions,
  markCompleteCoachingSession
} from '../../redux/actions/coachingSessions';
import { endOfToday, format, isAfter, isBefore, isPast, isToday, parseISO, subHours } from 'date-fns';
import { ICoachingSessionFullDTO, User } from '../Coaching/CoachingSessionFullDTO';
import { CoachingSessionStatus } from '../Coaching/coaching.enum';
import { useHistory } from 'react-router-dom';
import GroupParticipantsDetails from '../Coaching/GroupParticipantsDetails';
import { displaySessionDuration } from '../../utils/basic.utils';
import EventIcon from '@mui/icons-material/Event';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarsIcon from '@mui/icons-material/Stars';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { ConfirmationDialog } from '../../components/dialogs/ConfirmationDialog';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import LabelIcon from '@mui/icons-material/Label';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import NotesEditor from '../Coaching/NotesEditor';

interface CohortDetails {
  totalSessions: number;
  progress: number;
  cohortName: string;
  participants: User[];
  activeSession: ICoachingSessionFullDTO;
  nextSession: ICoachingSessionFullDTO;
  cohortSessions: ICoachingSessionFullDTO[];
  uncompletedSessions: ICoachingSessionFullDTO[];
}

const LeaderDashboard = () => {
  const dispatch = useDispatch();
  const userId = localStorage.getItem('LOCELLE:USER');
  const allSessions = useSelector(getCoachingSessions);
  const history = useHistory();
  const [openGroupDetailsDialog, setOpenGroupDetailsDialog] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [completeSessionDialog, setCompleteSessionDialog] = React.useState({ id: null, date: null });
  const [openNoteEditor, setOpenNoteEditor] = useState(false);
  const [cohortId, setCohortId] = useState(null);

  useEffect(() => {
    dispatch(findCoachingSessionsByUserId(userId));
  }, [dispatch, userId]);

  const isLeaderOrCoLeader: boolean = allSessions?.some(
    (s) => s.groupDto?.leader?.id === userId || s.groupDto?.coLeader?.id === userId
  );

  const sessions: ICoachingSessionFullDTO[] = allSessions?.filter(
    (s) => s?.groupDto?.leader?.id === userId || s?.groupDto?.coLeader?.id === userId
  );
  // sort sessions to be in ascending order
  sessions?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  // get cohorts to be used for the dashboard info
  const cohortsToDisplay = sessions
    ?.filter((s) => s.status !== CoachingSessionStatus.Completed && s.status !== CoachingSessionStatus.Cancelled) // not show cohorts with completed sessions at all
    ?.map((s) => s.group) // Extract the group from each session
    ?.filter(
      (group, index, self) => index === self.findIndex((g) => g.id === group.id) // Filter out duplicates based on group.id
    )
    ?.filter((g) => g.status === GroupStatus.Active);

  // all calculation for how sessions are displayed
  const getCohortDetails = (groupId): CohortDetails => {
    const now = new Date();
    const twoHoursAgo = subHours(now, 2); // 2 hours ago
    const endOfDay = endOfToday();

    const cohortSessions = sessions?.filter(
      (s) => s.group.id === groupId && s?.status !== CoachingSessionStatus.Cancelled
    );
    const totalSessions = cohortSessions?.length || 0;
    const cohortName = cohortSessions[0]?.group?.description;
    const completedSessions = cohortSessions?.filter(
      (session) => session?.status === CoachingSessionStatus.Completed || new Date(session?.date) < now
    ).length;
    const progress = cohortSessions ? (completedSessions / totalSessions) * 100 : 0;
    const todaySession = cohortSessions
      ?.filter((session) => {
        const sessionDate = new Date(session?.date);
        return (
          session?.status === CoachingSessionStatus.Confirmed &&
          isToday(sessionDate) &&
          isAfter(sessionDate, twoHoursAgo) &&
          isBefore(sessionDate, endOfDay)
        ); // Within the 2-hour past and before the end of the day
      })
      ?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) // Sort by closest to now
      ?.find((session) => isAfter(new Date(session?.date), now) || isBefore(new Date(session?.date), now));

    const nextSession = cohortSessions.find(
      (session) => session?.status === CoachingSessionStatus.Confirmed && new Date(session?.date) > now
    );
    const activeSession = todaySession ? todaySession : nextSession;
    const uncompletedSessions = cohortSessions?.filter(
      (session) =>
        session?.status === CoachingSessionStatus.Confirmed &&
        isPast(new Date(session?.date)) &&
        session !== activeSession
    );
    const participants = cohortSessions[0]?.groupDto?.participants;

    return {
      totalSessions,
      cohortName,
      progress,
      participants,
      cohortSessions,
      activeSession,
      nextSession,
      uncompletedSessions
    };
  };

  const handleClose = (): void => {
    setOpenGroupDetailsDialog(false);
    setOpenNoteEditor(false);
    setCohortId(null);
    setParticipants([]);
  };

  return (
    <>
      {isLeaderOrCoLeader && cohortsToDisplay?.length > 0 && (
        <Card
          elevation={0}
          sx={{
            minWidth: '300px',
            backgroundColor: Colors.BackgroundMain,
            boxShadow: 'rgba(0, 0, 0, 0.1) -4px 9px 25px -6px',
            borderRadius: 4,
            pb: 2,
            maxWidth: '100%',
            '&:hover': {
              boxShadow: '0 0 10px 5px rgba(177, 224, 180, 0.19)'
            }
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              sx={{ fontSize: '1.3rem', ml: 1, color: Colors.TextElevatedLighter, fontWeight: 'bold', mb: 3 }}
            >
              Your Cohorts
            </Typography>
            <Box sx={{ width: '100%' }}>
              {cohortsToDisplay?.map((cohort) => {
                const cohortDetails = getCohortDetails(cohort.id);

                return (
                  <Accordion
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                      pb: 2,
                      pt: 1
                    }}
                    defaultExpanded
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDownwardIcon color="success" />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography sx={{ color: Colors.TextElevated, fontWeight: 'bold' }}>
                        {cohortDetails?.cohortName || ''}{' '}
                        <span
                          style={{
                            color: Colors.TextElevatedLighter,
                            fontWeight: 'initial',
                            marginLeft: '10px',
                            fontSize: '0.9rem'
                          }}
                        >
                          (click to expand/collapse)
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack
                        key={cohort.id}
                        spacing={{ xs: 1, sm: 2 }}
                        direction="row"
                        sx={{ flexWrap: 'wrap', mb: 3, alignItems: 'stretch' }}
                      >
                        {/* Cohort Info Section */}
                        <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 500 }}>
                          <Paper
                            elevation={0}
                            sx={{
                              flexGrow: 1,
                              flexBasis: { xs: '100%', sm: '25%' },
                              backgroundColor: Colors.BackgroundMainLighter,
                              p: 1,
                              boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                              color: Colors.TextElevated,

                              borderRadius: 4,
                              transition: 'box-shadow 0.3s ease-in-out',
                              '&:hover': {
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
                              }
                            }}
                          >
                            <Typography
                              gutterBottom
                              sx={{ color: 'green', fontSize: '1rem', textAlign: 'center', mb: 2 }}
                            >
                              Cohort
                            </Typography>
                            <Divider sx={{ mb: 1, width: '95%', backgroundColor: Colors.BackgroundMainLighter }} />
                            <Typography gutterBottom>
                              <span style={{ color: 'grey' }}>Cohort name: </span>
                              <span style={{ color: Colors.TextElevated, fontSize: '1rem' }}>
                                {cohortDetails?.cohortName || ''}
                              </span>
                            </Typography>
                            <Typography gutterBottom sx={{ color: Colors.TextElevated, fontSize: '1rem' }}>
                              <span style={{ color: 'grey' }}>Number of participants: </span>
                              {cohortDetails?.participants?.length}
                            </Typography>
                            {!!cohortDetails?.nextSession && (
                              <Typography gutterBottom sx={{ color: Colors.TextElevated, fontSize: '1rem' }}>
                                <span style={{ color: 'grey' }}>Next session: </span>
                                {cohortDetails?.nextSession
                                  ? format(parseISO(cohortDetails?.nextSession?.date), 'EEE, MMM d, yyyy - h mm a')
                                  : ''}
                              </Typography>
                            )}
                            <Typography gutterBottom sx={{ color: Colors.TextElevated, fontSize: '1rem' }}>
                              <span style={{ color: 'grey' }}>Number of sessions scheduled: </span>
                              {cohortDetails?.totalSessions || 0}
                            </Typography>

                            {/*Mark Complete section*/}
                            {!!cohortDetails?.uncompletedSessions?.length && (
                              <>
                                <Divider sx={{ my: 1, width: '95%', backgroundColor: Colors.BackgroundMainLighter }} />
                                <Stack direction="row" spacing={1} sx={{ my: 2 }}>
                                  <Box>
                                    <NotificationImportantIcon sx={{ color: 'lightsalmon', textAlign: 'center' }} />
                                  </Box>
                                  <Typography sx={{ color: Colors.TextElevated, fontSize: '1rem' }}>
                                    There {cohortDetails?.uncompletedSessions?.length === 1 ? 'is' : 'are'}{' '}
                                    <b>{cohortDetails?.uncompletedSessions?.length}</b> passed{' '}
                                    {cohortDetails?.uncompletedSessions?.length === 1 ? 'session' : 'sessions'} to be
                                    marked as Complete:
                                  </Typography>
                                </Stack>
                                {cohortDetails?.uncompletedSessions?.map((uncompletedSession) => (
                                  <Box
                                    key={uncompletedSession?.id}
                                    sx={{ display: 'flex', alignItems: 'center', justifyItems: 'flex-start', mb: 2 }}
                                  >
                                    <LabelIcon
                                      sx={{ color: Colors.TextElevatedLighter, fontSize: 'small', mr: 0.5, ml: 1 }}
                                    />{' '}
                                    <Typography sx={{ width: '56%' }}>
                                      {format(parseISO(uncompletedSession?.date), 'EEE, MMM d, yyyy - h mm a')}
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      color="success"
                                      sx={{ backgroundColor: Colors.TextElevatedLighter, height: '25px' }}
                                      size="small"
                                      onClick={() => {
                                        setCompleteSessionDialog({
                                          id: uncompletedSession?.id,
                                          date: uncompletedSession?.date
                                        });
                                      }}
                                    >
                                      Mark Complete
                                    </Button>
                                  </Box>
                                ))}
                              </>
                            )}
                          </Paper>
                        </Grow>

                        {/* Participants Section */}
                        <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 }}>
                          <Paper
                            sx={{
                              flexGrow: 1,
                              flexBasis: { xs: '100%', sm: '15%' },
                              textAlign: 'center',
                              backgroundColor: Colors.BackgroundMainLighter,
                              color: Colors.TextElevated,
                              fontSize: '1rem',
                              p: 1,
                              boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                              borderRadius: 4,
                              position: 'relative',
                              transition: 'box-shadow 0.3s ease-in-out',
                              '&:hover': {
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
                              }
                            }}
                          >
                            <Typography
                              gutterBottom
                              sx={{ color: 'green', fontSize: '1rem', textAlign: 'center', mb: 2 }}
                            >
                              Participants
                            </Typography>
                            <Divider
                              sx={{ mb: 2, width: '95%', backgroundColor: Colors.BackgroundMainLighter, mx: 'auto' }}
                            />
                            <Box sx={{ mb: 6 }}>
                              {cohortDetails?.participants?.map((participant) => (
                                <Box
                                  key={participant?.id}
                                  sx={{ display: 'flex', alignItems: 'center', mb: 0.8, ml: 1 }}
                                >
                                  <Avatar
                                    sx={{
                                      backgroundColor: Colors.TextElevated,
                                      width: 24,
                                      height: 24,
                                      fontSize: '0.9rem'
                                    }}
                                  >
                                    {participant?.name?.charAt(0)}
                                  </Avatar>
                                  <Box sx={{ ml: 1 }}>{participant?.name}</Box>
                                </Box>
                              ))}
                            </Box>
                            <Button
                              variant="outlined"
                              color="success"
                              size="small"
                              startIcon={<PeopleAltIcon color="success" />}
                              sx={{ position: 'absolute', bottom: 12, right: 12, mt: 2 }}
                              onClick={() => {
                                setOpenGroupDetailsDialog(true);
                                setParticipants(cohortDetails?.participants);
                              }}
                            >
                              Details
                            </Button>
                          </Paper>
                        </Grow>

                        {/* Sessions Section */}
                        <Grow in={true} style={{ transformOrigin: '0 0 0', cursor: 'pointer' }} {...{ timeout: 1500 }}>
                          <Paper
                            sx={{
                              flexGrow: 1,
                              flexBasis: { xs: '100%', sm: '50%' },
                              textAlign: 'center',
                              backgroundColor: Colors.BackgroundMainLighter,
                              color: Colors.TextElevated,
                              fontSize: '1rem',
                              p: 1,
                              boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                              borderRadius: 4,
                              transition: 'box-shadow 0.3s ease-in-out',
                              '&:hover': {
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
                              }
                            }}
                          >
                            <Typography
                              gutterBottom
                              sx={{ color: 'green', fontSize: '1rem', textAlign: 'center', mb: 2 }}
                            >
                              Sessions
                            </Typography>
                            {/*Linear Progress line*/}
                            <Box sx={{ marginBottom: 2, display: 'flex', alignItems: 'center' }}>
                              <Box sx={{ width: '100%' }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={cohortDetails?.progress}
                                  sx={{
                                    height: '15px',
                                    borderRadius: 3,
                                    mx: 'auto',
                                    backgroundColor: Colors.Active,
                                    '& .MuiLinearProgress-bar': {
                                      backgroundColor: Colors.TextElevatedLighter
                                    }
                                  }}
                                />
                              </Box>
                              <Box>
                                <Typography variant="body2" sx={{ color: 'text.secondary', ml: 0.5 }}>{`${Math.round(
                                  cohortDetails?.progress
                                )}%`}</Typography>
                              </Box>
                            </Box>
                            {cohortDetails?.cohortSessions?.map((session, index) => (
                              <Box
                                key={session?.id}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyItems: 'flex-start',
                                  mb: 0.8,
                                  cursor: 'pointer',
                                  mx: 1,
                                  borderRadius: 2,
                                  backgroundColor:
                                    session?.date === cohortDetails?.activeSession?.date
                                      ? Colors.SelectedDrawerBackground
                                      : null,
                                  color:
                                    session?.date === cohortDetails?.activeSession?.date
                                      ? 'green'
                                      : session?.date < cohortDetails?.activeSession?.date
                                      ? 'gray'
                                      : Colors.TextElevated
                                }}
                                onClick={() => {
                                  history?.push('/sessions');
                                }}
                              >
                                {session?.date < cohortDetails?.activeSession?.date ||
                                  (new Date(session?.date) < new Date() && (
                                    <CheckCircleIcon
                                      fontSize="medium"
                                      sx={{
                                        color: cohortDetails?.uncompletedSessions?.includes(session)
                                          ? 'lightsalmon'
                                          : Colors.TextElevated,
                                        mr: 1
                                      }}
                                    />
                                  ))}
                                {session?.date > cohortDetails?.activeSession?.date && (
                                  <EventIcon sx={{ color: Colors.ButtonGreen, mr: 1 }} fontSize="medium" />
                                )}
                                {session?.date === cohortDetails?.activeSession?.date && (
                                  <StarsIcon sx={{ mr: 1 }} fontSize="medium" />
                                )}

                                <Box
                                  sx={{
                                    ml: 1,
                                    fontWeight: 'bold',
                                    width: '55%',
                                    textAlign: 'start',
                                    pb: 0.1,
                                    mx: 'auto'
                                  }}
                                >
                                  {session?.topic ||
                                    (session?.stage === SessionStageType.MeetAndGreet
                                      ? 'First Session'
                                      : session?.stage)}
                                  :
                                </Box>
                                <Box
                                  sx={{
                                    ml: 1,
                                    width: '45%',
                                    textAlign: 'start',
                                    mx: 'auto'
                                  }}
                                >
                                  {format(parseISO(session?.date), 'EEE, MMM d, yyyy - h mm a')} (
                                  {displaySessionDuration(session?.duration)})
                                </Box>
                              </Box>
                            ))}

                            {/*View Notes*/}
                            <Button
                              sx={{
                                backgroundColor: Colors.TextElevatedLighter,
                                border: 0,
                                color: 'white',
                                height: '30px',
                                float: 'right',
                                my: 3,
                                mr: 1
                              }}
                              variant="contained"
                              color="success"
                              onClick={() => {
                                setCohortId(cohort?.id);
                                setParticipants(cohortDetails?.participants);
                                setOpenNoteEditor(true);
                              }}
                            >
                              View Notes
                            </Button>
                          </Paper>
                        </Grow>
                        <GroupParticipantsDetails
                          isOpen={openGroupDetailsDialog}
                          handleClose={handleClose}
                          participants={participants}
                        />
                        <ConfirmationDialog
                          isOpen={!!completeSessionDialog?.id}
                          title="Complete Session?"
                          message="Are you sure you want to mark this session complete? It will be moved to COMPLETED section of Sessions page for all participants."
                          onConfirmBtn={() => {
                            dispatch(
                              markCompleteCoachingSession(
                                completeSessionDialog?.id,
                                CoachingSessionStatus.Completed,
                                completeSessionDialog.date
                              )
                            );
                            setCompleteSessionDialog({ id: null, date: null });
                          }}
                          onCancelBtn={() => setCompleteSessionDialog({ id: null, date: null })}
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </CardContent>
        </Card>
      )}
      <Dialog maxWidth="md" open={openNoteEditor} onClose={handleClose}>
        <DialogContent sx={{ p: 1, pt: 0, height: '900px', backgroundColor: Colors.BackgroundMain }}>
          <NotesEditor cohortId={cohortId} participants={participants} onClose={handleClose} userId={userId} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LeaderDashboard;